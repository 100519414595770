import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Solution-driven Outcomes',
    headline: 'Bespoke Solutions',
    description: 'Tailor-made solutions that best fit your organization for optimal performance. It’s never too complex, never too expensive.',
    buttonLabel: 'Get Started',
    imgStart: true,
    img: require('../../images/more_art.jpeg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,

};



export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Who we are',
    headline: 'How we do it',
    description: 'At Codescoot, we are a tech company driven by a passion for innovation and a commitment to excellence. With our expertise in web development, app development, and tailored tech solutions, we help businesses thrive in the digital world. Our team of skilled professionals combines creativity, technical prowess, and a customer-centric approach to deliver exceptional results. We believe in the power of technology to transform businesses, and we work closely with our clients to understand their unique needs and provide customized solutions that drive growth and success. Partner with us and experience the Codescoot difference in unlocking the full potential of your digital initiatives.'
    ,
    buttonLabel: 'Learn More',
    imgStart: false,
    img: require('../../images/pop_tech.jpeg').default,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true,

};

export const homeObjThree = {
    id: 'contactUs',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Contact Us for a free consultation.',
    headline: "Let's talk",
    description: (
        <>
            Give us a call anytime. Our team is ready to solve your tech problems. Harness the power of strategic insights to fuel your success. Our expert guidance empowers informed decisions and actionable strategies, driving sustainable growth for your business. <br/><br/>
            <FontAwesomeIcon icon={faPhone} /> +880 1707078906<br /><br />
            <FontAwesomeIcon icon={faEnvelope} /> codescoot@gmail.com<br /><br />
            <FontAwesomeIcon icon={faMapMarkerAlt} /> House 57, Road 3, Sector 5, Uttara, Dhaka
        </>
    ),
    buttonLabel: 'Call Now',
    buttonLink: 'tel:+8801707078906',
    imgStart: true,
    img: require('../../images/undraw_calling_re_mgft.svg').default,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true,
};



