import React, {useState, useEffect} from 'react'
import {FaBars} from 'react-icons/fa'

import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavBtn,
    NavBtnLink,
    NavBTnLinkContact
} from './NavbarElements';

const Navbar = ( {toggle}) => {

    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = ()=> {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
        setScrollNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, []);

    return (
      <>

            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to='/'>
                        codeScoot
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="about">
                                about
                            </NavLinks>

                        </NavItem>
                        <NavItem>
                            <NavLinks to="discover">
                            discover
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="services">
                                services
                            </NavLinks>
                        </NavItem>

                        {/*<NavItem>*/}
                        {/*    <NavLinks to="signup">*/}
                        {/*        Sign Up*/}
                        {/*    </NavLinks>*/}
                        {/*</NavItem>*/}
                    </NavMenu>
                </NavbarContainer>
            </Nav>

      </>
    );
};

export default Navbar
