import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa'
import { FooterContainer,FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink, SocialMedia, SocialLogo, WebsiteRights, SocialIconLink, SocialIcons, SocialMediaWrap  } from './FooterElements'
const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                {/*<FooterLinksContainer>*/}
                {/*    <FooterLinksWrapper>*/}
                {/*        <FooterLinkItems>*/}
                {/*            <FooterLinkTitle>About Us </FooterLinkTitle>*/}
                {/*                <FooterLink to="/Faqs">FAQs</FooterLink>*/}
                {/*                <FooterLink to="/HowItWorks">How it works</FooterLink>*/}
                {/*                <FooterLink to="/ServiceAreas">Service Areas</FooterLink>*/}
                {/*                <FooterLink to="/TermsOfService">Terms of Service</FooterLink>*/}
                {/*                <FooterLink to="/SignIn">Careers</FooterLink>*/}
                {/*        </FooterLinkItems>*/}

                {/*        <FooterLinkItems>*/}
                {/*            <FooterLinkTitle>Videos</FooterLinkTitle>*/}
                {/*                <FooterLink to="/">Our Videos</FooterLink>*/}
                {/*                <FooterLink to="/">Ambassadors</FooterLink>*/}
                {/*                <FooterLink to="/">Agency</FooterLink>*/}
                {/*                <FooterLink to="/">Influencer</FooterLink>*/}
                {/*        </FooterLinkItems>*/}

                {/*        <FooterLinkItems>*/}
                {/*            <FooterLinkTitle>Contact Us </FooterLinkTitle>*/}
                {/*                <FooterLink to="/">Contact</FooterLink>*/}
                {/*                <FooterLink to="/">Support</FooterLink>*/}
                {/*                <FooterLink to="/">Sponsorships</FooterLink>*/}
                {/*                <FooterLink to="/">I am a vendor</FooterLink>*/}
                {/*                */}
                {/*        </FooterLinkItems>*/}

                {/*        <FooterLinkItems>*/}
                {/*            <FooterLinkTitle>Social Media</FooterLinkTitle>*/}
                {/*                <FooterLink to="/">Instagram</FooterLink>*/}
                {/*                <FooterLink to="/">Facebook</FooterLink>*/}
                {/*                <FooterLink to="/">Youtube</FooterLink>*/}
                {/*                <FooterLink to="/">Twitter</FooterLink>*/}
                {/*                */}
                {/*        </FooterLinkItems>*/}
                {/*    </FooterLinksWrapper>*/}
                {/*</FooterLinksContainer>*/}

                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/'>
                            codeScoot
                        </SocialLogo>
                        <WebsiteRights>
                            codeScoot © {new Date().getFullYear()} All rights reserved.
                        </WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="/" target="_blank"
                                aria-label="Facebook">
                                    <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank"
                                aria-label="Instagram">
                                    <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank"
                                aria-label="Youtube">
                                    <FaYoutube />
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank"
                                aria-label="Twitter">
                                    <FaTwitter />
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank"
                                aria-label="Linkedin">
                                    <FaLinkedin />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
